import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="cogwheel" width="40%" />
        <div className='App-div'>
          <a
            className="App-link"
            href="mailto:info@hefai.com"
          >
            Contact us
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
